import React, { Component, ReactNode } from "react";
import { ScreenCloud } from "../../ScreenCloudReactApp";
import {EditorContainer} from "../EditorContainer/EditorContainer";
import {RouteComponentProps} from "react-router";

export interface DashboardConfigurationProps {
    dashboardType: string
    versionId: string
}

interface Props extends RouteComponentProps<DashboardConfigurationProps> {
    sc?: ScreenCloud;
}

export class AppContainer extends Component<Props> {
    render(): JSX.Element {
        return (
          <div className="app-container">
              <EditorContainer {...this.props}/>
          </div>
        );
    }
}
