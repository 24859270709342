import request from "superagent";
import randomstring from "randomstring";

export interface DashboardConfiguration {
    type: string;
    version: string;
}

export interface CreateSiteRequest {
    Name?: string;
    TargetUrl: string;
    CredentialId?: string;
    DashboardConfiguration: DashboardConfiguration;
}

export const createRendererSite = async (token: string, createSiteRequest: CreateSiteRequest): Promise<string> => {

    if (!createSiteRequest.Name) {
        createSiteRequest.Name = `${createSiteRequest.DashboardConfiguration.type}-${randomstring.generate({length: 6, charset: "alphabetic"})}`
    }

    const response = await request
        .post(`${process.env.REACT_APP_BASE_CONTROLLER_URL}/v1/management/sites`)
        .type("json")
        .set("Authorization", `Bearer ${token}`)
        .retry(2)
        .timeout({response: 5000, deadline: 20000})
        .send(createSiteRequest);

    const locationHeader = response.header.location;
    return locationHeader.substring(locationHeader.lastIndexOf("/") + 1);
};
