import React, { Component, ReactNode } from "react";
import CloudRenderingConfiguration from "../../components/CloudRenderingScreen"
import { ScreenCloud } from "../../ScreenCloudReactApp";
import { Grid, GridRow, GridColumn } from "@screencloud/screencloud-ui-components";
import {RouteComponentProps} from "react-router";
import {DashboardConfigurationProps} from "../AppContainer/AppContainer";
import {createRendererSite} from "../../lib/api/api";

interface Credential {
    id: string;
    description: string;
    provider: string;
    username: string;
    credentialKey: string;
    iconUrl?: string | null | undefined;
}

interface ConfigUpdateRequest {
    type: "preview" | "save";
}

interface EditorContainerProps extends RouteComponentProps<DashboardConfigurationProps> {
    sc?: ScreenCloud;
}

interface AppConfig {
    dashboardUrl: string
    credential?: Credential
    refreshIntervalSeconds: number
    refreshInterval: number
    refreshIntervalUnits: string
    siteId?: string
    tags: string[]
}

interface EditorContainerState {
    dashboardUrl: string
    credential?: Credential
    refreshIntervalSeconds: number
    refreshInterval: number
    refreshIntervalUnits: string
    siteId?: string
    tags: string[]
    errors: any
}

export class EditorContainer extends Component<EditorContainerProps, EditorContainerState> {

    constructor(props: EditorContainerProps) {
        super(props);

        this.state = {
            dashboardUrl: "",
            credential: undefined,
            refreshIntervalSeconds: 60,
            refreshInterval: 60,
            refreshIntervalUnits: "s",
            siteId: undefined,
            tags: [],
            errors: {}
        };

        props.sc!.onRequestConfigUpdate(this.onRequestConfigUpdate);
    }

    componentDidMount(): void {
        const config = this.props.sc!.config as EditorContainerState;
        this.setState(config)
    }

    render(): ReactNode {
        return (
            <Grid columns={2}>
                <GridRow>
                    <GridColumn width={5}>
                        <CloudRenderingConfiguration
                            dashboardUrl={this.state.dashboardUrl}
                            onDashboardUrlChange={this.onDashboardUrlChange}
                            credential={this.state.credential}
                            onRequestCredential={this.onRequestCredential}
                            onRemoveCredential={this.onRemoveCredential}
                            refreshInterval={this.state.refreshInterval}
                            onRefreshIntervalChange={this.onRefreshIntervalChange}
                            refreshIntervalUnits={this.state.refreshIntervalUnits}
                            onRefreshIntervalUnitsChange={this.onRefreshUnitsChange}
                            tags={this.state.tags}
                            onTagsChange={this.onTagsChange}
                            errors={this.state.errors}
                        />
                    </GridColumn>
                    <GridColumn width={11}>
                        <div className="cloud-rendering-preview">
                            <div className="wrapper">
                                <div className="inner">
                                    <div className="contents">
                                        <p>No Preview</p>
                                    </div>
                                </div>
                            </div>
                        </div>   
                    </GridColumn>
                </GridRow>
            </Grid>
        );
    }

    onDashboardUrlChange = async (event: any): Promise<void> => {
        const dashboardUrl = event.target.value;
        this.setState({ dashboardUrl }, this.validateForm);
    };

    onRequestCredential = async (event: any): Promise<void> => {
        const { credential } = await this.props.sc!.requestCredential({filter: ""});
        this.setState({ credential }, this.validateForm);
    };

    onRemoveCredential = async (): Promise<void> => {
        this.setState({ credential: undefined }, this.validateForm);
    }

    onRefreshIntervalChange = async (event: any): Promise<void> => {
        const { target: { value } } = event;

        this.setState({
            refreshInterval: Number(value),
            refreshIntervalSeconds: this.calculateRefreshIntervalSeconds(Number(value), this.state.refreshIntervalUnits)
        }, this.validateForm);
    };

    onRefreshUnitsChange = async (event: any, data: any): Promise<void> => {
        this.setState({
            refreshIntervalUnits: data.value,
            refreshIntervalSeconds: this.calculateRefreshIntervalSeconds(this.state.refreshInterval, data.value)
        }, this.validateForm);
    };

    onTagsChange = async (event: any): Promise<void> => {
        const data: string[] = event.values.map((v: { value: string }) => v.value);
        const tags = Array.from(new Set(data));

        if (JSON.stringify(tags) === JSON.stringify(this.state.tags)) {
            return
        }

        this.setState({ tags }, this.validateForm);
    };


    onRequestConfigUpdate =  async (request: ConfigUpdateRequest): Promise<{config?: AppConfig}> => {
        const { credential, dashboardUrl, errors, refreshIntervalSeconds, refreshInterval, refreshIntervalUnits, tags } = this.state;
        const isCredentialRequired = this.isCredentialRequired();

        let siteId = this.state.siteId;

        // Only create the site if the form data is valid
        if (
            Object.keys(errors).length === 0 &&
            (!isCredentialRequired || (isCredentialRequired && credential)) &&
            dashboardUrl !== this.props.sc!.config.dashboardUrl
        ) {
            siteId = await this.createSite();
        }
        
        return {
            config: {
                dashboardUrl,
                credential, 
                refreshIntervalUnits, 
                siteId, 
                tags,
                // Ensure the refresh interval saved is at least 1
                refreshIntervalSeconds: Math.max(1, refreshIntervalSeconds), 
                refreshInterval: Math.max(1, refreshInterval), 
            }
        };
    };

    calculateRefreshIntervalSeconds = (refreshInterval: number, intervalUnits: string): number => {
        switch(intervalUnits) {
            case "h":
                return (refreshInterval * 60) * 60;
            case "m":
                return refreshInterval * 60;
            case "s":
            default:
                return refreshInterval;
        }
    };

    createSite = async () => {
        const {dashboardUrl, credential} = this.state;
        const {dashboardType, versionId} = this.props.match.params;

        const authTokenHolder = await this.props.sc!.requestAuthToken();
        const siteId = await createRendererSite(authTokenHolder.authToken, {TargetUrl: dashboardUrl, CredentialId: credential ? credential.credentialKey : undefined, DashboardConfiguration: {type: dashboardType, version: versionId}});
        console.log(siteId);
        
        return siteId;
    }

    getFormErrors = () => {
        const errors: any = {};
        const { dashboardUrl, refreshInterval } = this.state;

        if (dashboardUrl.trim() === "" || !this.isDashboardUrlValid(dashboardUrl)) {
            errors.dashboardUrl = "Enter a valid dashboard URL";
        }

        if (refreshInterval <= 0) {
            errors.refreshInterval = "Enter a number greater than 0"
        }

        return errors;
    }

    validateForm = () => {
        const errors = this.getFormErrors();
        this.setState({ errors });

        if (this.isCredentialRequired() && !this.state.credential) {
            return
        }

        if (Object.keys(errors).length === 0) {
            this.props.sc!.emitConfigUpdateAvailable();
        }
    }

    isDashboardUrlValid = (url: string) => url.startsWith("https://") || url.startsWith("http://");
    isCredentialRequired = () => this.props.match.params.dashboardType !== "nologin";
}
