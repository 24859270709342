import React from "react";
import { DropdownItemProps } from "semantic-ui-react";
import { 
    Button, 
    Card, 
    CardContent, 
    Input,
    Grid, 
    GridRow, 
    GridColumn,
    MultilineTagInput,
    Thumbnail,
    Dropdown,
    Icon,
} from "@screencloud/screencloud-ui-components";

export interface CloudRenderingConfigurationProps {
    dashboardUrl: string
    onDashboardUrlChange: Function
    credential?: any
    onRequestCredential: (e: any) => Promise<void>
    onRemoveCredential: () => Promise<void>
    refreshInterval: number
    onRefreshIntervalChange: (e: any) => Promise<void>
    refreshIntervalUnits: string
    onRefreshIntervalUnitsChange: (e: any, d: any) => Promise<void>
    tags: string[]
    onTagsChange: (e: any) => Promise<void>
    errors: any
}

const timeUnits: DropdownItemProps[] = [ { key: 1, text: "seconds", value: "s" }, { key: 2, text: "minutes", value: "m" }, {key: 3, text: "hours", value: "h"} ];

const CloudRenderingConfiguration: React.FC<CloudRenderingConfigurationProps> = (props: CloudRenderingConfigurationProps) => {

    let login;

    if (props.credential === undefined) {
        login = (
            <div className="login-button">
                <Button outline onClick={props.onRequestCredential}>Add Login</Button>
            </div>
        );
    } else {
        login = (
            <div>
                <Grid columns={3}>
                    <GridRow>
                        <GridColumn width={3}>
                            <div style={{position: 'relative',minHeight: '100px'}}>
                                <Thumbnail size="medium" src={props.credential!.iconUrl} />
                            </div>
                        </GridColumn>
                        <GridColumn width={11}>
                            <div className="credential-details">
                                <p className="elipsis-content">{props.credential!.description}</p>
                                <p className="elipsis-content">{props.credential!.username}</p>
                            </div>
                        </GridColumn>
                        <GridColumn style={{ margin: 'Auto' }} width={2}>
                            <div className="credential-remove-button">
                                <Button transparent onClick={props.onRemoveCredential}>
                                    <Icon name="remove-fill"></Icon>
                                </Button>
                            </div>
                        </GridColumn>
                    </GridRow>
                </Grid>
            </div>
        );
    }

    return (
        <div className="cloud-rendering-config">
            <Grid divided="vertically">
                <GridRow columns={1}>
                    <div className="config-panel">
                        <h3>Dashboard URL</h3>
                        <p>Input a valid dashboard URL</p>
                        <Input
                            name="url-to-render"
                            placeholder="Please enter a dashboard URL"
                            type="text"
                            onChange={(event, data) => props.onDashboardUrlChange(event)}
                            value={props.dashboardUrl}
                            className="config-panel"
                            error={!!props.errors.dashboardUrl}
                        />
                        {props.errors.dashboardUrl && <p className="error-message">{props.errors.dashboardUrl}</p>}
                    </div>
                    <div className="config-panel">
                        <h3>Add Login</h3>
                        <p>If your dashboard or page requires login details they can be added here</p>
                        <Card className={`login-card ${!!props.errors.credential ? "error" : ""}`}>
                            <CardContent>
                                <div>
                                    {login}
                                </div>
                            </CardContent>
                        </Card>
                        {props.errors.credential && <p className="error-message">{props.errors.credential}</p>}
                    </div>
                    <div className="config-panel">
                        <h3>Refresh Interval</h3>
                        <div className="config-panel">
                            <div style={{display: "inline-block"}}>
                                <Input type="number"
                                       value={props.refreshInterval}
                                       onBlur={props.onRefreshIntervalChange}
                                       onChange={props.onRefreshIntervalChange}
                                       error={!!props.errors.refreshInterval}
                                />
                            </div>
                            <div style={{display: "inline-block", paddingLeft: "15px"}}>
                                <Dropdown
                                    inline
                                    options={timeUnits}
                                    onChange={props.onRefreshIntervalUnitsChange}
                                    value={props.refreshIntervalUnits}
                                />
                            </div>
                            {props.errors.refreshInterval && <p className="error-message">{props.errors.refreshInterval}</p>}
                        </div>
                    </div>
                </GridRow>
                <GridRow columns={1}>
                    <div className="config-panel">
                        <h3>Tags</h3>
                        <p>Enter a tag, and then press the 'Enter' key.</p>
                        <MultilineTagInput
                            value=""
                            currentValue={null}
                            values={props.tags.map(t => { return {value: t, isValidValue: true}})}
                            onChange={(e) => {} }
                            callback={props.onTagsChange}
                        />

                    </div>
                </GridRow>
            </Grid>
        </div>
    );
};

export default CloudRenderingConfiguration;
